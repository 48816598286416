/*!
 * BootstrapVue Icons Custom CSS (https://bootstrap-vue.org)
 */
.bv-no-focus-ring:focus {
  outline: none;
}

@media (max-width: 575.98px) {
  .bv-d-xs-down-none {
    display: none !important;
  }
}
@media (max-width: 767.98px) {
  .bv-d-sm-down-none {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .bv-d-md-down-none {
    display: none !important;
  }
}
@media (max-width: 1199.98px) {
  .bv-d-lg-down-none {
    display: none !important;
  }
}
.bv-d-xl-down-none {
  display: none !important;
}

.b-icon.bi {
  display: inline-block;
  overflow: visible;
  vertical-align: -0.15em;
}
.b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon, .b-icon.b-iconstack .b-icon-animation-cylon > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-cylon-vertical;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-cylon-vertical, .b-icon.b-iconstack .b-icon-animation-cylon-vertical > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-fade;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-fade, .b-icon.b-iconstack .b-icon-animation-fade > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 2s infinite linear normal b-icon-animation-spin;
  animation: 2s infinite linear normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin, .b-icon.b-iconstack .b-icon-animation-spin > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  animation: 2s infinite linear reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse, .b-icon.b-iconstack .b-icon-animation-spin-reverse > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 1s infinite steps(8) normal b-icon-animation-spin;
  animation: 1s infinite steps(8) normal b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-pulse, .b-icon.b-iconstack .b-icon-animation-spin-pulse > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  animation: 1s infinite steps(8) reverse b-icon-animation-spin;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-spin-reverse-pulse, .b-icon.b-iconstack .b-icon-animation-spin-reverse-pulse > g {
    -webkit-animation: none;
    animation: none;
  }
}
.b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
  animation: 0.75s infinite ease-in-out alternate b-icon-animation-throb;
}
@media (prefers-reduced-motion: reduce) {
  .b-icon.b-icon-animation-throb, .b-icon.b-iconstack .b-icon-animation-throb > g {
    -webkit-animation: none;
    animation: none;
  }
}

@-webkit-keyframes b-icon-animation-cylon {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
  100% {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}

@keyframes b-icon-animation-cylon {
  0% {
    -webkit-transform: translateX(-25%);
    transform: translateX(-25%);
  }
  100% {
    -webkit-transform: translateX(25%);
    transform: translateX(25%);
  }
}
@-webkit-keyframes b-icon-animation-cylon-vertical {
  0% {
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
  }
  100% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
}
@keyframes b-icon-animation-cylon-vertical {
  0% {
    -webkit-transform: translateY(25%);
    transform: translateY(25%);
  }
  100% {
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
}
@-webkit-keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@keyframes b-icon-animation-fade {
  0% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes b-icon-animation-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes b-icon-animation-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes b-icon-animation-throb {
  0% {
    opacity: 0.5;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.btn .b-icon.bi,
.nav-link .b-icon.bi,
.dropdown-toggle .b-icon.bi,
.dropdown-item .b-icon.bi,
.input-group-text .b-icon.bi {
  font-size: 125%;
  vertical-align: text-bottom;
}

/*# sourceMappingURL=bootstrap-vue-icons.css.map */